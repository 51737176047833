<template>
    <div class="site" v-if="loaded">
        <WizardHeader :brand="brand" />
        <div class="main">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <IntroPage v-if="wizard.currentStep === 0" :brand="brand" :lang="getLanguageCode()" :serviceUrl="serviceUrl" @start="startWizard()" />
                        <template v-else-if="wizard.currentStep === 1">
                            <WizardTitle :brand="brand" :step="wizard.currentStep" />
                            <WizardSteps :firstStep="true" />
                            <StepDevices
                                ref="stepDevices"
                                :userData="userData"
                                :brand="brand"
                                :deviceDescriptionInvalid="deviceDescriptionInvalid"
                                :devicePurchaseDateInvalid="devicePurchaseDateInvalid"
                                :v="$v"
                            />
                            <StepAddDevices
                                :userData="userData"
                                :brand="brand"
                                :show-add-device="wizard.showAddDevice"
                                :v="$v"
                                @hideAddDevice="hideAddDeviceButton"
                            />
                        </template>

                        <template v-else-if="wizard.currentStep === 2">
                            <WizardTitle :brand="brand" :step="wizard.currentStep" />
                            <WizardSteps :firstStep="false" />
                            <StepPersonalInfoNL v-if="country.toLowerCase() === 'nl'" :userData="userData" :brand="brand" :v="$v" :serviceUrl="serviceUrl" />
                            <StepPersonalInfoBE v-if="country.toLowerCase() === 'be'" :userData="userData" :brand="brand" :v="$v" :serviceUrl="serviceUrl" />
                        </template>

                        <template v-else-if="wizard.currentStep === 3">
                            <WizardTitle :brand="brand" :step="wizard.currentStep" />
                            <StepConfirm
                                :userData="userData"
                                :wizard="wizard"
                                :brand="brand"
                                :v="$v"
                                :lang="getLanguageCode()"
                                @editDevices="goToStep(1, false)"
                                @editPersonalData="goToStep(2, false)"
                            />
                        </template>

                        <ResultPage v-else-if="wizard.currentStep === 4" :brand="brand" :lang="getLanguageCode()" :serviceUrl="serviceUrl" />

                        <template v-else>
                            <WizardTitle :title="$tr('oeps')" :subtitle="$tr('gingietsmis')" :brand="brand" :step="wizard.currentStep" />
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="wizard.currentStep !== 0">
            <template v-if="wizard.currentStep === 1">
                <WizardButtonsWrapper alignbuttons="justify-content-between" v-if="userData.articlelist.length > 0">
                    <button class="btn btn-outline-primary" @click="deleteArticle" v-if="!wizard.showAddDevice">
                        <i class="fal fa-arrow-left"></i>
                        <span v-html="$tr('selecteerapparaat')"></span>
                    </button>
                    <button class="btn btn-danger" disabled v-if="deviceDescriptionInvalid || devicePurchaseDateInvalid || $v.userData.articlelist.$error">
                        <span v-html="$tr('ongeldigeinvoervelden')"></span>
                        <i class="fal fa-times"></i>
                    </button>
                    <button class="btn btn-next" @click="goToStep(2, true)" v-else>
                        <span v-html="$tr('naarstap')"></span><span> 2</span>
                        <i class="fal fa-arrow-right"></i>
                    </button>
                </WizardButtonsWrapper>
            </template>

            <WizardButtonsWrapper alignbuttons="justify-content-between" v-else-if="wizard.currentStep === 2">
                <button class="btn btn-outline-primary" @click="goToStep(1, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('pasapparaatgegevensaan')"></span>
                </button>
                <button class="btn btn-danger" disabled v-if="$v.$error">
                    <span v-html="$tr('fouten')"></span>
                    <i class="fal fa-times"></i>
                </button>
                <button class="btn btn-next" @click="goToStep(3, true)" v-else>
                    <span v-html="$tr('naarstap')"></span><span> 3</span>
                    <i class="fal fa-arrow-right"></i>
                </button>
            </WizardButtonsWrapper>

            <WizardButtonsWrapper alignbuttons="justify-content-between" v-else-if="wizard.currentStep === 3">
                <button class="btn btn-outline-primary" @click="goToStep(2, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('aanpassenpersoonlijkegegevens')"></span>
                </button>
                <button class="btn btn-danger" disabled v-if="wizard.validationTerms && (!userData.terms || !userData.termsInstaller)">
                    <span v-html="$tr('accepteervoorwaarden')"></span>
                    <i class="fal fa-times"></i>
                </button>
                <button class="btn btn-next" @click="sendForm()" v-else>
                    <span v-html="$tr('verzenden')"></span><span></span>
                    <i class="fal fa-arrow-right"></i>
                </button>
                <div class="c-wizard__btns--loader" v-if="loading">
                    <span class="c-wizard__btns--loader--spinner"><i class="fal fa-spinner-third"></i></span>
                    <span class="c-wizard__btns--loader--txt" v-html="$tr('aanvraagverstuurd.')"></span>
                </div>
            </WizardButtonsWrapper>

            <template v-else-if="wizard.currentStep === 4"> </template>

            <WizardButtonsWrapper alignbuttons="justify-content-center" v-else>
                <button class="btn btn-outline-primary" @click="goToStep(1, false)">
                    <i class="fal fa-arrow-left"></i>
                    <span v-html="$tr('beginovernieuw')"></span>
                </button>
            </WizardButtonsWrapper>
        </template>
        <WizardFooter :brand="brand" :lang="getLanguageCode()" :serviceUrl="serviceUrl" />
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import IntroPage from './components/intropage/intropage.vue';
import ResultPage from './components/resultpage/resultpage.vue';
import StepDevices from './components/steps/step-devices.vue';
import StepAddDevices from './components/steps/step-add-devices.vue';
import StepPersonalInfoNL from './components/steps/step-personalinfo-nl.vue';
import StepPersonalInfoBE from './components/steps/step-personalinfo-be.vue';
// import StepPurchaseLocation from './components/steps/step-purchaselocation.vue';
import StepConfirm from './components/steps/step-confirm.vue';
import WizardHeader from './components/wizard/wizard-header.vue';
import WizardFooter from './components/wizard/wizard-footer.vue';
import WizardSteps from './components/wizard/wizard-steps.vue';
import WizardButtonsWrapper from './components/wizard/wizard-buttons-wrapper.vue';
import WizardTitle from './components/wizard/wizard-title.vue';

// eslint-disable-next-line no-unused-vars
import { required, email, numeric, requiredIf, alphaNum } from 'vuelidate/lib/validators';

export default {
    name: 'storingmelden',
    data() {
        return {
            loaded: false,
            loading: false,
            serviceUrl: this.url,
            wizard: {
                currentStep: 0,
                showAddDevice: true,
                validationTerms: false
            },
            userData: {
                terms: false,
                language: this.language.toUpperCase(),
                country: this.country.toLowerCase(),
                retailer: this.retailer.toLowerCase() === 'true',
                date: '', // moment().format('DD-MM-YYYY'),
                articlelist: [],
                salutation: '',
                lastName: '',
                address: {
                    city: '',
                    houseNumber: '',
                    houseNumberSuffix: '',
                    streetName: '',
                    zipCode: ''
                },
                phoneNumber: '',
                emailAddress: '',
                client: {
                    clientType: '',
                    clientCity: '',
                    clientCompanyName: '',
                    clientContactPerson: '',
                    clientNumber: ''
                },
                shouldShowDescriptionDropdowns: false,
                dropdownOptions: [[]],
                chosenDropdownOptions: [],
                textFieldOption: null,
                textFieldOptionResult:""
            }
        };
    },
    watch: {
        'userData.articlelist': function () {
            if (this.userData.articlelist.length === 0) {
                this.wizard.showAddDevice = true;
            }
        }
    },
    props: { language: String, brand: String, url: String, country: String, retailer: String },
    components: {
        IntroPage,
        ResultPage,
        StepPersonalInfoNL,
        StepPersonalInfoBE,
        StepAddDevices,
        StepDevices,
        StepConfirm,
        WizardHeader,
        WizardFooter,
        WizardSteps,
        WizardTitle,
        WizardButtonsWrapper
    },
    methods: {
        hideAddDeviceButton() {
            this.wizard.showAddDevice = false;
        },
        goToStep(pag, validate, event) {
            if (validate) {
                if (this.wizard.currentStep === 1) {
                    this.$v.userData.articlelist.$touch();
                    if (!this.$v.userData.articlelist.$error) {
                        this.wizard.currentStep = pag;
                        this.scrollToTop();
                    }
                } else {
                    this.$v.$touch();
                    if (!this.$v.$error) {
                        this.wizard.currentStep = pag;
                        this.scrollToTop();
                    }
                }
            } else {
                this.wizard.currentStep = pag;
                this.scrollToTop();
            }
        },
        startWizard() {
            this.wizard.currentStep = 1;
            this.scrollToTop();
            document.body.classList.remove('intropage');
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getLanguageCode() {
            if (this.userData.language.toLowerCase() === 'fr') return 'fr';
            else if (this.userData.country.toLowerCase() === 'nl') return 'nl';
            else return 'be';
        },
        sendForm() {
            this.$v.$touch();
            if (this.$v.$error || this.userData.articlelist.length === 0) {
                return;
            }
            
            if (!this.userData.terms || !this.userData.termsInstaller) {
                this.wizard.validationTerms = true;
                return; 
            }
            
            this.loading = true;
            let data = this.userData;
            let article = this.userData.articlelist[0];
            let formData = new FormData();

            formData.append('Brand', this.brand);
            formData.append('ModelNumber', article.modelNumber);
            formData.append('ArticleNumber', article.articleNumber);
            formData.append('VersionNumber', article.versionNumber);

            let formattedpurchaseDate = '';
            if (article.userData.purchaseDate) {
                formattedpurchaseDate = moment(article.userData.purchaseDate).format('DD-MM-YYYY');
            } else {
                formattedpurchaseDate = "01-01-0000";
            }
            formData.append('DatePurchased', formattedpurchaseDate);

            formData.append('SerialNumber', article.userData.serialnumber);
            formData.append('FailureDescription', article.userData.description);

            for (let i = 0; i < article.userData.photos.length; i++) {
                formData.append('Photos[' + i + '].Name', article.userData.photos[i].image.name);
                formData.append('Photos[' + i + '].Prefix', article.userData.photos[i].prefix);
                formData.append('Photos[' + i + '].Base64', article.userData.photos[i].base64);
            }


            formData.append('ClientSalutation', data.salutation);
            formData.append('ClientSurname', data.lastName);
            formData.append('ClientStreet', data.address.streetName);

            formData.append('ClientHouseNumber', data.address.houseNumber);
            formData.append('ClientHouseNumberPostfix', data.address.houseNumberSuffix);
            formData.append('ClientZipCode', data.address.zipCode);
            formData.append('ClientCity', data.address.city);
            formData.append('ClientCountry', data.country);

            formData.append('ClientTelephone', data.phoneNumber.replace('-', ''));
            formData.append('ClientEmail', data.emailAddress);
            if (data.client.clientType === 'client') {
                formData.append('InvoiceContact', data.client.clientContactPerson);
                formData.append('InvoiceCompany', data.client.clientCompanyName);
                formData.append('InvoiceClientNumber', data.client.clientNumber);
                formData.append('InvoiceCity', data.client.clientCity);
            }
            formData.append('SiteLanguage', this.userData.language);

            axios
                .post(`/api/complaint/PostComplaint`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.toResultPage();
                })
                .catch((e) => {});
        },
        toResultPage() {
            this.goToStep(4, false);
            this.loading = false;
        },
        isLocal() {
            let localhost = window.location.hostname === 'localhost';
            return localhost;
        },
        deleteArticle() {
            this.userData.articlelist = [];
        }
    },
    computed: {
        deviceDescriptionInvalid() {
            if (this.userData.articlelist.length > 0) {
                var device = this.userData.articlelist[0];

                // description has no length req. when dropdowns are shown
                if(this.userData.shouldShowDescriptionDropdowns){
                    if(device.userData.description.length === 0){
                        return true;
                    }
                }else if (device.userData.description === '' || device.userData.description.length < 9) {
                    return true;
                }
                return false;
            }
            return false;
        },
        devicePurchaseDateInvalid() {
            if (this.userData.purchaseDateUnkown) {
                return false;
            }
            if (this.userData.articlelist.length > 0) {
                var device = this.userData.articlelist[0];

                if (device.userData.purchaseDate === '' || device.userData.purchaseDate === null) {
                    return true;
                }
                return false;
            }
            return false;
        },
        userTypeClient() {
            return this.userData.client.clientType === 'client';
        }
    },
    validations: {
        userData: {
            lastName: {
                required
            },
            articlelist: {
                $each: {
                    userData: {
                        serialnumber: {
                            requiredIf: requiredIf(function (object) {
                                const currentProductGroup = this.userData.articlelist[0].productGroup;
                                if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                                    return true;
                                }
                                return false;
                            }),
                            validSerialnumber: function (object) {
                                // only check for hisense and tv
                                const currentProductGroup = this.userData.articlelist[0].productGroup;
                                if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                                    const regex = /^[A-Za-z0-9]{23}$/;
                                    return regex.test(object);
                                } else {
                                    return true;
                                }
                            }
                        },
                        photos: {
                            isValidInvoice: function () {
                                const currentProductGroup = this.userData.articlelist[0].productGroup;

                                if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                                    return this.userData.articlelist[0].userData.photos.filter((photo) => photo.prefix === "invoice").length === 1;

                                } else {
                                    return true;
                                }
                            },
                            isValidType: function () {
                                const currentProductGroup = this.userData.articlelist[0].productGroup;

                                if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                                    return this.userData.articlelist[0].userData.photos.filter((photo) => photo.prefix === "type").length === 1;
                                } else {
                                    return true;
                                }
                            }
                        }
                    }
                }
            },
            address: {
                zipCode: {
                    required,
                    maxLength: function () {
                        if (!this.userData.address.zipCode) {
                            return true;
                        }
                        return this.country === 'nl' ? this.userData.address.zipCode.length < 7 : this.userData.address.zipCode.length < 5;
                    },
                    alphaNum,
                    validNLZipcode: function () {
                        if (this.country !== 'nl') {
                            return true;
                        }
                        if (this.userData.address.zipCode.match(/\d{4}[a-zA-Z]{2}/)) {
                            return true;
                        }
                        return false;
                    }
                },
                houseNumber: {
                    required,
                    numeric
                }
            },
            phoneNumber: {
                required,
                isValid: function () {
                    if (!this.userData.phoneNumber) {
                        return true;
                    }
                    if (this.country !== 'nl') {
                        if (this.userData.phoneNumber.match(/^\d{10}\d?$/)) {
                            return true;
                        }
                    } else {
                        if (this.userData.phoneNumber.includes('-')) {
                            if (this.userData.phoneNumber.length < 11) {
                                return false;
                            }
                        } else if (this.userData.phoneNumber.length < 10) {
                            return false;
                        }
                        if (this.userData.phoneNumber.match(/^\d+[\s-]?\d+$/)) {
                            return true;
                        }
                    }
                    return false;
                }
            },
            salutation: {
                required
            },
            emailAddress: {
                required,
                email
            },
            client: {
                clientType: {
                    required
                },
                clientNumber: {
                    required: requiredIf(function () {
                        return this.userTypeClient;
                    })
                },
                clientCompanyName: {
                    required: requiredIf(function () {
                        return this.userTypeClient;
                    })
                },
                clientContactPerson: {
                    required: requiredIf(function () {
                        return this.userTypeClient;
                    })
                },
                clientCity: {
                    required: requiredIf(function () {
                        return this.userTypeClient;
                    })
                }
            }
        }
    },
    mounted() {
        if (this.wizard.currentStep === 0) {
            document.body.classList.add('intropage');
        }
    },
    created: function () {
        // haalt alle vertalingen op en zet ze in i18n vuex store
        this.$store.dispatch('setLanguage', this.language.toLowerCase() + '-' + this.country.toUpperCase()).then((x) => {
            this.loaded = true;
        });
    }
};
</script>
