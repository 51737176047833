<template>
    <header class="c-header">
        <div class="logo"><img :src="`/img/${brand}/logo.svg`" /></div>
    </header>
</template>

<script>
export default {
    name: 'wizard-header',
    data() {
        return {};
    },
    props: {
        brand: {
            type: String,
            required: true
        }
    },
    computed: {}
};
</script>
