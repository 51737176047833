export default {
    setLanguage(state, payload) {
        if (state.i18n.translations[payload.locale] === undefined) {
            state.i18n.translations[payload.locale] = {};
        }

        if (payload.translations === '') {
            state.i18n.translations[payload.locale] = {};
        } else {
            state.i18n.translations[payload.locale] = payload.translations;
        }
        state.i18n.locale = payload.locale;
        state.i18n.baseLocaleLoaded = true;
    },
    setTranslation(state, payload) {
        if (state.i18n.translations[payload.locale] === undefined) {
            state.i18n.translations[payload.locale] = {};
        }
        state.i18n.translations[payload.locale][payload.key] = payload.value;
    }
};
