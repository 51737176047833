import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import store from './store';
import storingmelden from './modules/storingmelden/storingmelden.vue';
import Vuelidate from 'vuelidate';
import vuexI18n from 'vuex-i18n';
import Autocomplete from 'vue2-autocomplete-js';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.component('autocomplete', Autocomplete);
Vue.use(require('vue-moment'));

Vue.use(vuexI18n.plugin, store);

Vue.mixin({
    methods: {
        convertFileToBase64String(file) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = error => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        },
        $tr(key,defaultFallback){
            let translation = this.$t(key,"no_translation_found");
            if(translation === "no_translation_found"){
                if(defaultFallback){
                    return defaultFallback;
                }else{
                    return "[" + key + "]";
                }
            }
            return translation;
        }        
    }
});

const router = new VueRouter({
    mode: 'history'
});

function init() {
    const elStoringmelden = document.getElementById('storingmelden');

    if (elStoringmelden !== null && elStoringmelden !== undefined) {
        // ReSharper disable once ConstructorCallNotUsed
        new Vue({
            el: '#storingmelden',
            router,
            store,
            render: (h) =>
                h(storingmelden, {
                    props: {
                        language: elStoringmelden.getAttribute('data-language') || 'nl',
                        brand: elStoringmelden.getAttribute('data-brand') || 'atag',
                        url: elStoringmelden.getAttribute('data-url') || '#',
                        country: elStoringmelden.getAttribute('data-country') || 'nl',
                        retailer: elStoringmelden.getAttribute('data-retailer') || false
                    }
                })
        });
    }
}

export default {
    init
};
