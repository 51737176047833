import axios from 'axios';

export default {
    setLanguage(context, payloadLocale) {
        let params = (new URL(document.location)).searchParams;
        let country = params.get("country") || 'nl';
        let language = params.get("language") || 'NL';
        let brand = params.get("brand") || 'Atag';
        return new Promise((resolve, reject) => {
            axios
                .get(`/api/translation/GetTranslations?country=${country}&language=${language}&brand=${brand}`)
                .then(response => {
                    context.commit('setLanguage', { locale: payloadLocale, translations: response.data });
                    resolve();
                })
                .catch(e => {
                    console.log('Something went wrong retrieving translations for locale!', e);
                    reject(e);
                });
        });
    }
}
