<template>
    <div class="c c-intropage">
        <div class="c-intropage__heading">
            <h1 v-html="$tr('storingmelden')"></h1>
        </div>
        <div class="c c-intropage__content">
            <h2 v-html="$tr('wehelpenjegraagverder')"></h2>
            <p v-html="$tr('apparaatopzoekenstoringdoorgeven')"></p>
            <h3 v-html="$tr('handigomteweten')"></h3>
            <ul class="c-arrowlist">
                <li v-if="shouldShowListItem('informatieoveronderhoud')" v-html="$tr('informatieoveronderhoud')"></li>
                <li v-if="shouldShowListItem('monteursbezoekwordtingepland')" v-html="$tr('monteursbezoekwordtingepland')"></li>
                <li v-if="shouldShowListItem('intropagebulletpoint3')" v-html="$tr('intropagebulletpoint3')"></li>
            </ul>
            <button @click="$emit('start')" :class="['btn', getButtonStyle]">
                <span v-html="$tr('jouwstoringmelden')"></span>
                <i class="fal fa-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'intropage',
    data() {
        return {};
    },
    computed: {
        getButtonStyle() {
            if (this.brand === 'etna' || this.brand === 'hisense') {
                return 'btn-primary';
            } else if (this.brand === 'atag') {
                return 'btn-outline-secondary';
            }

            return 'btn-secondary';
        },
        getServicePageLink() {
            return this.serviceUrl;
        },
        getInfoPageLink() {
            return this.serviceUrl;
        }
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            required: true
        },
        serviceUrl: {
            type: String,
            required: true
        }
    },
    methods: {
        shouldShowListItem(label){
            const displayLabel = '[' + label + ']';
            if(displayLabel === this.$tr(label)){
                return false;
            }
            return true;
        }
    }
};
</script>
