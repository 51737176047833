<template>
    <div class="c c-resultpage">
        <div class="c-resultpage__heading">
            <h1 v-html="$tr('storinggemeld')"></h1>
        </div>
        <div class="c c-resultpage__content">
            <h3 v-html="$tr('meldingontvangen')"></h3>
            <p v-html="$tr('snelmogelijkcontact')"></p>
            <a :href="getWebsiteLink" :class="['btn', getButtonStyle]" :title="$tr('naarwebsite')">
                <span v-html="$tr('naarwebsite')"></span>
                <i class="fal fa-arrow-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'resultpage',
    data() {
        return {};
    },
    computed: {
        getButtonStyle() {
            if (this.brand === 'etna' || this.brand === 'hisense') {
                return 'btn-primary';
            } else if (this.brand === 'atag') {
                return 'btn-outline-secondary';
            }

            return 'btn-secondary';
        },
        getWebsiteLink() {
            return this.serviceUrl;
        }
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            required: true
        },
        serviceUrl: {
            type: String,
            required: true,
            default: 'https://www.atag.nl'
        }
    }
};
</script>
