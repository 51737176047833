import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import actions from './actions';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const state = {
    langKeysSearching: {}
};

export default new Vuex.Store({
    namespaced: true,
    modules: {},
    state,
    actions,
    mutations,
    strict: debug
});
