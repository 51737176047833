<template>
    <footer class="c-footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="c-footer__contact" v-if="getContactUrl()">
                        <a :href="getContactUrl()" target="_blank" rel="nofollow" title="Contact" v-html="$tr('contact')"></a>
                        <a :href="getPrivacyStatementUrl()" target="_blank" rel="nofollow" title="Privacy statement" v-html="$tr('privacystatement')"></a>
                    </div>
                    <div class="c-footer__copyright">
                        <span v-html="$tr('copyright')"></span> <span>{{ getYear() }}</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'wizard-footer',
    data() {
        return {};
    },
    methods: {
        getContactUrl() {
            return this.serviceUrl;
        },
        getPrivacyStatementUrl() {
            return this.serviceUrl;
        },
        getYear() {
            const year = new Date().getFullYear();
            if (year !== 2022) {
                return '2022-' + year;
            }
            return year;
        }
    },
    props: {
        brand: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            required: true
        },
        serviceUrl: {
            type: String,
            required: true
        }
    }
};
</script>
