<template>
    <div class="c c-personalinfo">
        <div class="c c-stap" :validator="v.form">
            <h3 class="c-stap__header">
                <span v-html="$tr('waarmonteurbezoeken')"></span>
                <button class="btn btn-secondary" @click="prefillform" v-if="isLocal()" style="position: fixed; top: 9px; right: 9px" v-html="$tr('vulformulierautomatisch')" />
            </h3>
            <div class="c-stap__body">
                <div class="form-group row">
                    <label for="inputState" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                        <span class="required-field" v-html="($tr('aanhef') + '*')"></span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <select :class="`form-control ${v.userData.salutation.$error ? 'input-validation-error' : ''}`" v-model="userData.salutation">
                            <option value="" v-html="$tr('kiezen')"></option>
                            <option value="M" v-html="$tr('man')"></option>
                            <option value="V" v-html="$tr('vrouw')"></option>
                            <option value="X" v-html="$tr('anders')"></option>
                        </select>
                        <div class="text-sm mt-2 text-red" v-if="v.userData.salutation.$error">
                            <div v-if="!v.userData.salutation.required" v-html="getValidationMessage($tr('aanhef'), 'required')"></div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="name" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                        <span class="required-field" v-html="($tr('achternaam') + '*')"></span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <input
                            type="text"
                            :class="`form-control ${v.userData.lastName.$error ? 'input-validation-error' : ''}`"
                            id="name"
                            v-model="userData.lastName"
                            name="family-name"
                            @input="v.userData.lastName.$touch()"
                        />
                        <div class="text-sm mt-2 text-red" v-if="v.userData.lastName.$error">
                            <div v-if="!v.userData.lastName.required" v-html="getValidationMessage($tr('achternaam'), 'required')"></div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="zipcode" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                        <span class="required-field" v-html="($tr('postcode') + '*')"></span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <input
                            type="text"
                            :class="`form-control ${v.userData.address.zipCode.$error ? 'input-validation-error' : ''}`"
                            id="zipcode"
                            name="postal-code"
                            v-model="userData.address.zipCode"
                            @input="v.userData.address.zipCode.$touch()"
                            @keyup="upperCase()"
                            v-on:change="getCityAndStreet"
                        />
                        <div class="text-sm mt-2 text-red" v-if="v.userData.address.zipCode.$error">
                            <div v-if="!v.userData.address.zipCode.required" v-html="getValidationMessage($tr('postcode'), 'required')"></div>
                            <template v-else>
                                <div v-if="!v.userData.address.zipCode.validNLZipcode" v-html="getValidationMessage($tr('postcode'), 'invalid')"></div>
                                <div v-if="!v.userData.address.zipCode.maxLength" v-html="getValidationMessage($tr('postcode'), 'maxlength6')"></div>
                                <div v-if="!v.userData.address.zipCode.alphaNum" v-html="getValidationMessage($tr('postcode'), 'alphanum')"></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                        <label for="housenumber">
                            <span class="required-field" v-html="($tr('huisnummer') + '*')"></span>
                        </label>
                        <span> &amp; </span>
                        <label for="suffix" v-html="$tr('toevoeging')"></label>
                    </div>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <div class="d-flex">
                            <input
                                type="text"
                                :class="`form-control form-medium ${v.userData.address.houseNumber.$error ? 'input-validation-error' : ''}`"
                                id="housenumber"
                                v-model="userData.address.houseNumber"
                                @input="v.userData.address.houseNumber.$touch()"
                                v-on:change="getCityAndStreet"
                                style="margin-right: 20px"
                            />
                            <input
                                type="text"
                                class="form-control form-medium"
                                id="suffix"
                                v-model="userData.address.houseNumberSuffix"
                                v-on:change="getCityAndStreet"
                            />
                        </div>
                        <template v-if="userData.address.streetName != null && userData.address.streetName !== ''">
                            <div class="mt-2">
                                {{ userData.address.streetName }} {{ userData.address.houseNumber }} {{ userData.address.houseNumberSuffix }}<br />
                                {{ userData.address.zipCode }}<br />
                                {{ userData.address.city }}
                            </div>
                        </template>
                        <template v-if="invalidAdress">
                            <div class="mt-2 p-2 adress-error">
                                <span v-html="$tr('ongeldigadres')"></span>
                            </div>
                        </template>
                        <div class="text-sm mt-2 text-red" v-if="v.userData.address.houseNumber.$error">
                            <div v-if="!v.userData.address.houseNumber.required" v-html="getValidationMessage($tr('huisnummer'), 'required')"></div>
                            <div v-else-if="!v.userData.address.houseNumber.numeric" v-html="getValidationMessage($tr('huisnummer'), 'invalid')"></div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                        <span>
                            <span class="required-field" v-html="($tr('emailadres') + '*')"></span>
                        </span>
                        <span class="customtooltip">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix"><small v-html="$tr('geldigemailadres')"></small></span>
                        </span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <input
                            type="text"
                            :class="`form-control ${v.userData.emailAddress.$error ? 'input-validation-error' : ''}`"
                            id="email"
                            value="email@example.com"
                            name="email"
                            v-model="userData.emailAddress"
                            @input="v.userData.emailAddress.$touch()"
                        />
                        <div class="text-sm mt-2 text-red" v-if="v.userData.emailAddress.$error">
                            <div v-if="!v.userData.emailAddress.required" v-html="getValidationMessage($tr('emailadres'), 'required')"></div>
                            <div v-else-if="!v.userData.emailAddress.email" v-html="getValidationMessage($tr('emailadres'), 'invalid')"></div>
                        </div>
                    </div>
                </div>
                <p v-html="$tr('telefoontussentekst')"></p>
                <div class="form-group row">
                    <label for="phone" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label hastooltip">
                        <span>
                            <span class="required-field" v-html="($tr('telefoonnummer') + '*')"></span>
                        </span>
                        <span class="customtooltip">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix"><small v-html="$tr('geldigtelefoonnummer')"></small></span>
                        </span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <input
                            type="text"
                            :class="`form-control ${v.userData.phoneNumber.$error ? 'input-validation-error' : ''}`"
                            id="phone"
                            v-model="userData.phoneNumber"
                            @input="acceptPhoneNumber"
                        />
                        <div class="text-sm mt-2 text-red" v-if="v.userData.phoneNumber.$error">
                            <div v-if="!v.userData.phoneNumber.required" v-html="getValidationMessage($tr('telefoonnummer'), 'required')"></div>
                            <div v-if="!v.userData.phoneNumber.isValid" v-html="getValidationMessage($tr('telefoonnummer'), 'invalid')"></div>
                        </div>
                    </div>
                </div>
            </div>
            <RequiredFieldExplanation />
        </div>

        <div v-if="isRetailer()" class="c-stap">
            <h3 class="c-stap__header">
                <span v-html="$tr('aanvullendegegevens')"></span>
            </h3>
            <div class="c-stap__body">
                <div class="form-group row">
                    <label class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                        <span v-html="$tr('klantsoort')"></span> <span class="required-field">*</span>
                    </label>
                    <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                        <div class="form-check" v-for="option in optionsClientType" :key="option.key">
                            <input
                                :id="option.key"
                                class="custom-control-input"
                                type="radio"
                                :value="option.key"
                                v-model="userData.client.clientType"
                                name="clientType"
                            />
                            <label :for="option.key" class="custom-control-label">{{ option.label }}</label>
                        </div>
                        <div class="text-sm mt-2 text-red" v-if="v.userData.client.clientType.$error">
                            <div v-if="!v.userData.client.clientType.required" v-html="getValidationMessage($tr('klantsoort'), 'required')"></div>
                        </div>
                    </div>
                </div>
                <template v-if="userTypeClient">
                    <div class="form-group row">
                        <label for="clientNumber" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                            <span v-html="$tr('klantnummer')"></span> <span class="required-field">*</span>
                        </label>
                        <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                            <input
                                type="text"
                                :class="`form-control ${v.userData.client.clientNumber.$error ? 'input-validation-error' : ''}`"
                                id="clientNumber"
                                v-model="userData.client.clientNumber"
                                @input="v.userData.client.clientNumber.$touch()"
                            />
                            <div class="text-sm mt-2 text-red" v-if="v.userData.client.clientNumber.$error">
                                <div v-if="!v.userData.client.clientNumber.required" v-html="getValidationMessage($tr('klantnummer'), 'required')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="clientCompanyName" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                            <span v-html="$tr('bedrijfsnaam')"></span> <span class="required-field">*</span>
                        </label>
                        <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                            <input
                                type="text"
                                :class="`form-control ${v.userData.client.clientCompanyName.$error ? 'input-validation-error' : ''}`"
                                id="clientCompanyName"
                                v-model="userData.client.clientCompanyName"
                                @input="v.userData.client.clientCompanyName.$touch()"
                            />
                            <div class="text-sm mt-2 text-red" v-if="v.userData.client.clientCompanyName.$error">
                                <div v-if="!v.userData.client.clientCompanyName.required" v-html="getValidationMessage($tr('bedrijfsnaam'), 'required')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="clientContactPerson" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                            <span v-html="$tr('contactpersoon')"></span> <span class="required-field">*</span>
                        </label>
                        <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                            <input
                                type="text"
                                :class="`form-control ${v.userData.client.clientContactPerson.$error ? 'input-validation-error' : ''}`"
                                id="clientContactPerson"
                                v-model="userData.client.clientContactPerson"
                                @input="v.userData.client.clientContactPerson.$touch()"
                            />
                            <div class="text-sm mt-2 text-red" v-if="v.userData.client.clientContactPerson.$error">
                                <div v-if="!v.userData.client.clientContactPerson.required" v-html="getValidationMessage($tr('contactpersoon'), 'required')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="clientCity" class="col-xs-12 col-sm-5 col-md-3 col-xl-4 col-form-label">
                            <span v-html="$tr('plaats')"></span> <span class="required-field">*</span>
                        </label>
                        <div class="col-xs-12 col-sm-7 col-md-9 col-xl-8">
                            <input
                                type="text"
                                :class="`form-control ${v.userData.client.clientCity.$error ? 'input-validation-error' : ''}`"
                                id="clientCity"
                                v-model="userData.client.clientCity"
                                @input="v.userData.client.clientCity.$touch()"
                            />
                            <div class="text-sm mt-2 text-red" v-if="v.userData.client.clientCity.$error">
                                <div v-if="!v.userData.client.clientCity.required" v-html="getValidationMessage($tr('plaats'), 'required')"></div>
                            </div>
                        </div>
                    </div>
                </template>
                <RequiredFieldExplanation />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import RequiredFieldExplanation from '../../components/forms/required-field-explanation.vue';

export default {
    name: 'step-personalinfo-nl',
    data() {
        return {
            invalidAdress: false,
            phonenumber: '',
            isMobielNummer: false,
            optionsClientType: [
                {
                    key: 'client',
                    label: 'Voor de klant'
                },
                {
                    key: 'me',
                    label: 'Voor mij'
                }
            ]
        };
    },
    computed: {
        userTypeClient() {
            return this.userData.client.clientType === 'client';
        }
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        serviceUrl: {
            type: String,
            default: null,
            required: true
        }
    },
    components: {
        RequiredFieldExplanation
    },
    methods: {
        returnPartialPhoneNumber(matchResult, dashPosition) {
            let result = '';
            if (!matchResult) return result;

            // The 0th element is the total and not a group. We want to
            // return all the groups until an undefined or null is found.
            for (let i = 1; i < matchResult.length; i++) {
                if (matchResult[i] !== null && matchResult[i] !== undefined) {
                    result += matchResult[i];
                    if (i === dashPosition && matchResult[i + 1] !== null && matchResult[i + 1] !== undefined) result += '-';
                } else {
                    break;
                }
            }
            return result;
        },
        acceptPhoneNumber() {
            let phonenumber =  this.userData.phoneNumber;
            var vastNummer = /(0)([1-9])?([1-9])?([0-9])?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var vastNummer2 = /(0)([1-9])?([0-9])?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var mobielNummer = /(0)(6)?([1-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?([0-9])?/;
            var vast1 = phonenumber.replace(/\D/g, '').match(vastNummer);
            var vast2 = phonenumber.replace(/\D/g, '').match(vastNummer2);
            var mobiel = phonenumber.replace(/\D/g, '').match(mobielNummer);
            var partialVast1 = this.returnPartialPhoneNumber(vast1, 4);
            var partialVast2 = this.returnPartialPhoneNumber(vast2, 3);
            var partialMobiel = this.returnPartialPhoneNumber(mobiel, 2);

            if (partialMobiel.replace('-', '').length >= partialVast1.replace('-', '').length) {
                phonenumber = partialMobiel;
            } else if (partialVast1.replace('-', '').length >= partialVast2.replace('-', '').length) {
                phonenumber = partialVast1;
            } else {
                phonenumber = partialVast2;
            }
            this.userData.phoneNumber = phonenumber;
            this.v.userData.phoneNumber.$touch();
        },
        getValidationMessage(field, type) {
            if (type === 'invalid') {
                return field + ' ' + this.$tr('isnietgeldig');
            } else if (type === 'maxlength6') {
                return field + ' ' + this.$tr('maximalelengte6');
            } else if (type === 'alphanum') {
                return field + ' ' + this.$tr('ongeldigekarakters');
            } 
            return field + ' ' + this.$tr('isvereist');
        },
        getLanguageCode() {
            if (this.userData.language.toLowerCase() === 'fr') return 'fr';
            else if (this.userData.country.toLowerCase() === 'nl') return 'nl';
            else return 'be';
        },
        isLocal() {
            let localhost = window.location.hostname === 'localhost';
            return localhost;
        },
        isRetailer() {
            if (this.userData.retailer) {
                return true;
            } else {
                this.userData.client.clientType = 'me';
                return false;
            }
        },
        upperCase() {
            this.userData.address.zipCode = this.userData.address.zipCode.toUpperCase();
        },
        prefillform() {
            this.userData.salutation = 'V';
            this.userData.lastName = 'Anonymous';
            this.userData.address.zipCode = '7051cj';
            this.userData.address.houseNumber = '14';
            this.userData.address.houseNumberSuffix = 'b';
            this.userData.phoneNumber = '0612312312';
            this.userData.emailAddress = 'noreply@frontis.nl';
            this.userData.client.clientType = 'client';
            this.userData.client.clientNumber = '054322345';
            this.userData.client.clientCompanyName = 'ATAG Benelux';
            this.userData.client.clientContactPerson = 'Jan Janssen';
            this.userData.client.clientCity = 'Amsterdam';

            this.getCityAndStreet();
        },
        getCityAndStreet() {
            this.upperCase();
            axios
                .get(
                    `/api/address/GetAddress?country=${this.userData.country}&zipCode=${this.userData.address.zipCode}&number=${this.userData.address.houseNumber}`
                )
                .then((response) => {
                    if(response.data.street == null && response.data.city == null){
                        this.invalidAdress = true;
                    }else{
                        this.invalidAdress = false;
                    }
                    this.userData.address.streetName = response.data.street;
                    this.userData.address.city = response.data.city;
                })
                .catch((e) => {});
        }
    },
    mounted() {
        this.phonenumber = this.userData.phonenumber1 || this.userData.phonenumber2 || '';

        if (!this.userData.isRetailer) {
            this.userData.client.clientType = 'me';
        }
    }
};
</script>

<style lang="scss" scoped></style>
