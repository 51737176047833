<template>
    <div class="c c-stap" v-if="productsAdded">
        <h3 class="c-stap__header">
            <template v-if="userData.articlelist.length === 1" v-html="$tr('jouwapparaat')"></template>
            <template v-else v-html="$tr('jouwapparaten')"></template>
        </h3>
        <div class="c-stap__body">
            <p v-html="$tr('vulingevuldegegevensaan')"></p>
            <div class="c-device__overview">
                <Device
                    v-for="(article, index) in userData.articlelist"
                    :index="index"
                    :brand="brand"
                    :article="article"
                    :userData="userData"
                    :imageRight="true"
                    :key="article.productId"
                    :deviceDescriptionInvalid="deviceDescriptionInvalid"
                    :devicePurchaseDateInvalid="devicePurchaseDateInvalid"
                    :v="v"
                />
            </div>
        </div>
    </div>
</template>

<script>
import articleHelper from '../../../../util/articleHelper';
import Device from '../devices/device.vue';

export default {
    name: 'step-devices',
    data() {
        return { products: null, articleNumber: null, selectedBatch: null, productNotFound: false };
    },
    components: {
        Device
    },
    methods: {
        reformatArticleNumber(value) {
            return articleHelper.toReadableArticleNumber(value);
        },
        reformatModelNumber(value) {
            return articleHelper.toReadableModelNumber(value);
        },
    },
    computed: {
        productsAdded() {
            return this.userData.articlelist.length > 0;
        }
    },
    props: {
        userData: {
            type: Object,
            default: null,
            required: true
        },
        brand: {
            type: String,
            default: null,
            required: true
        },
        deviceDescriptionInvalid: {
            type: Boolean,
            default: false
        },
        devicePurchaseDateInvalid: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
