<template>
    <div class="c c-wizard__title text-center">
        <h1 v-html="getTitle"></h1>
        <h2 class="c-wizard__title--sub text-primary" v-if="getSubtitle != ''" v-html="getSubtitle"></h2>
    </div>
</template>

<script>
export default {
    name: 'wizard-title',
    data() {
        return {};
    },
    props: {
        brand: {
            type: String,
            required: false
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        title: {
            type: String,
            required: false
        },
        subtitle: {
            type: String,
            required: false
        }
    },
    computed: {
        getBrandname() {
            const brand = this.brand.toUpperCase();
            if (brand === 'PELGRIM') {
                return 'Pelgrim';
            } else if (brand === 'HISENSE') {
                return 'Hisense';
            }

            return brand;
        },
        getTitle() {
            if (this.title) {
                return this.title;
            }
            return this.$tr('titel');
        },
        getSubtitle() {
            if (this.subtitle) {
                return this.subtitle;
            }
            return this.$tr('subtitel');
        }
    }
};
</script>
