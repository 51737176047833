<template>
    <div class="c c-wizard__steps">
        <div class="c-wizard__steps--item" :class="{ active: firstStep, done: !firstStep }">
            <div class="c-wizard__steps--item--nr" v-if="firstStep">1</div>
            <div class="c-wizard__steps--item--nr" v-else><i class="fal fa-check"></i></div>
            <div class="c-wizard__steps--item--name" v-html="$tr('jeapparatuur')"></div>
        </div>
        <div class="c-wizard__steps--item" :class="{ active: !firstStep }">
            <div class="c-wizard__steps--item--nr">2</div>
            <div class="c-wizard__steps--item--name" v-html="$tr('jegegevens')"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wizard-steps',
    data() {
        return {};
    },
    props: {
        firstStep: Boolean
    }
};
</script>
