<template>
    <div class="c-device__item" :class="{ editable: editable }">
        <div class="c-device__inner">
            <div class="c-device__img" :class="{ 'order-lg-2': imageRight }">
                <img
                :src="`https://www.atagonline.nl/beeldenbank?imageName=${reformatModelNumber(article.modelNumber)}`"
                class="img-fluid"
                alt="img"
                v-if="!hideImage"
                @error="altImageUrl"
                />
            </div>

            <div class="c-device__info" :class="{ 'order-lg-1': imageRight }">
                <div class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl">
                            <span v-html="$tr('modelnummer')"></span>
                            <span class="customtooltip" v-if="editable">
                                <span class="tooltip-item"></span>
                                <span class="tooltip-content clearfix">
                                    <img :src="`/img/modelnummer-tooltip.png`" /><small v-html="$tr('modelnumbertooltip')"></small>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="c-device__val">{{ reformatModelNumber(article.modelNumber) }}</div>
                </div>
                <div class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl">
                            <span v-html="$tr('artikelnummer')"></span>
                            <span class="customtooltip" v-if="editable">
                                <span class="tooltip-item"></span>
                                <span class="tooltip-content clearfix">
                                    <img :src="`/img/artikelnummer-tooltip.png`" />
                                    <small v-html="$tr('articlenumbertooltip')"></small>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="c-device__val">{{ reformatArticleNumber(article.articleNumber) }}</div>
                </div>
                <div class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl">
                            <span v-html="$tr('versienummer')"></span>
                            <span class="customtooltip" v-if="editable">
                                <span class="tooltip-item"></span>
                                <span class="tooltip-content clearfix">
                                    <img :src="`/img/versienummer-tooltip.png`" />
                                    <small v-html="$tr('versionnumbertooltip')"></small>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <select id="inputState" class="form-control form-small" v-model="article.versionNumber">
                                <option v-for="versionNumber in article.versionNumbers" :key="versionNumber">{{ versionNumber }}</option>
                            </select>
                        </template>
                        <template v-else>
                            {{ article.versionNumber }}
                        </template>
                    </div>
                </div>
                <div class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl">
                            <span v-html="$tr('serienummer')"></span>
                            <span v-if="serialnumberRequired">*</span>
                            <span class="customtooltip" v-if="editable">
                                <span class="tooltip-item"></span>
                                <span class="tooltip-content clearfix">
                                    <img :src="`/img/serienummer-tooltip.png`" />
                                    <small v-html="$tr('serialnumbertooltip')"></small>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <input
                                type="text"
                                class="form-control"
                                @input="v.userData.articlelist.$each[index].userData.serialnumber.$touch()"
                                v-model="userData.articlelist[index].userData.serialnumber"
                                :placeholder="$tr('serienummervoorbeeld')"
                            />
                        </template>
                        <template v-else>
                            {{ userData.articlelist[index].userData.serialnumber }}
                        </template>
                        <div class="text-sm mt-2 text-red" v-if="!v.userData.articlelist.$each[index].userData.serialnumber.requiredIf">
                            <div>{{ getValidationMessage($tr('serienummer'), 'required') }}</div>
                        </div>
                        <div class="text-sm mt-2 text-red" v-else-if="!v.userData.articlelist.$each[index].userData.serialnumber.validSerialnumber">
                            <div>{{ getValidationMessage($tr('serienummer'), 'invalid') }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="productGroupIsHiSenseTv()" class="c-type__row">
                        <div class="c-type__key">
                            <div class="c-type__key--lbl">
                                <span v-if="isControll" v-html="$tr('foto')"></span>
                                <span v-else v-html="$tr('fotobijvoegentype')"></span>
                            </div>
                        </div>
                        <div class="c-type__val">
                            <div class="c-type__photos">
                                <template v-if="editable">
                                    <div v-for="(photo) in this.getUploadsWithPrefix('type')" class="c-type__photos--photo" :key="photo.image.name">
                                        <img v-if="photo.blobUrl !== ''" :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                        <div v-else class="c-type__photos--addphoto flex-column p-2">
                                            <i class="fal fa-file"></i>
                                            <small class="pt-1">{{ photo.image.name }}</small>
                                        </div>
                                        <button class="c-type__photos--deletephoto" @click="resetPhoto(photo.index, 'type')"><i class="fal fa-times"></i></button>
                                    </div>
                                    <div class="c-type__photos--addphoto" @click="$refs.uploadPhotoType.click()" v-if="this.getUploadsWithPrefix('type').length <= 0">
                                        <i class="fal fa-photo-video"></i>
                                        <i class="fal fa-plus"></i>
                                    </div>
                                    <input class="d-none" ref="uploadPhotoType"  type="file" accept=".jpg,.jpeg,.png" prefix="type" @change="photoChanged" />
                                </template>
                                <template v-else>
                                    <div v-for="photo in this.getUploadsWithPrefix('type')" class="c-type__photos--photo" :key="photo.image.name + '2'">
                                        <img :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                    </div>
                                </template>
                            </div>

                            <div class="text-sm mt-2 text-red" v-if="!v.userData.articlelist.$each[index].userData.photos.isValidType">
                                <div v-html="$tr('fotobijvoegentypefout')">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                <div class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl"><span v-html="$tr('aankoopdatum')"></span> <span v-if="editable" class="required-field">*</span></div>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <date-picker
                                placeholder="jjjj-mm-dd"
                                :disabled-date="beforeDateExclude"
                                v-model="article.userData.purchaseDate"
                                lang="lang"
                                format="YYYY-MM-DD"
                                :disabled="userData.purchaseDateUnkown"
                            ></date-picker>
                            <div class="form-check">
                                <input
                                    class="custom-control-input"
                                    type="checkbox"
                                    id="purchaseDateUnkown"
                                    v-on:click="clearPurchaseDate()"
                                    v-model="userData.purchaseDateUnkown"
                                />
                                <label class="custom-control-label" for="purchaseDateUnkown" v-html="$tr('aankoopdatum.onbekend')"></label>
                            </div>

                            <div v-if="userData.purchaseDateUnkown" class="text-sm mt-2">
                                <span v-html="$tr('aankoopdatum.onbekend.melding')"></span>
                            </div>

                            <div v-if="deviceAge >= warrantyYear" class="c-device__warning">
                                <i class="fal fa-exclamation-circle"></i>
                                <span v-html="$tr('apparaatouderdan' + warrantyYear + 'jaar')"></span>
                            </div>

                            <div v-else-if="deviceAge >= 2 && brand != 'Hisense'" class="c-device__warning">
                                <i class="fal fa-exclamation-circle"></i>
                                <span v-html="$tr('apparaatouderdan2jaar')"></span>
                            </div>
                            
                            <div class="text-sm mt-2 text-red invalid-date" v-html="$tr('datuminverleden')"></div>
                            <div class="text-sm mt-2 text-red" v-if="devicePurchaseDateInvalid">
                                <div>{{ getValidationMessage($tr('aankoopdatum'), 'required') }}</div>
                            </div>
                        </template>
                        <template v-else>
                            {{ getFormattedDate }}
                        </template>
                    </div>
                </div>

                <div v-if="productGroupIsHiSenseTv()" class="c-invoice__row">
                        <div class="c-invoice__key">
                            <div class="c-invoice__key--lbl">
                                <span v-if="isControll" v-html="$tr('foto')"></span>
                                <span v-else v-html="$tr('fotobijvoegenfactuur')"></span>
                            </div>
                        </div>
                        <div class="c-invoice__val">
                            <div class="c-invoice__photos">
                                <template v-if="editable">
                                    <div v-for="(photo) in this.getUploadsWithPrefix('invoice')" class="c-invoice__photos--photo" :key="photo.image.name">
                                        <img v-if="photo.blobUrl !== ''" :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                        <div v-else class="c-invoice__photos--addphoto flex-column p-2">
                                            <i class="fal fa-file"></i>
                                            <small class="pt-1">{{ photo.image.name }}</small>
                                        </div>
                                        <button class="c-invoice__photos--deletephoto" @click="resetPhoto(photo.index, 'invoice')"><i class="fal fa-times"></i></button>
                                    </div>
                                    <div class="c-invoice__photos--addphoto" @click="$refs.uploadPhotoInvoice.click()" v-if="this.getUploadsWithPrefix('invoice').length <= 0">
                                        <i class="fal fa-photo-video"></i>
                                        <i class="fal fa-plus"></i>
                                    </div>
                                    <input class="d-none" ref="uploadPhotoInvoice"  type="file" accept=".jpg,.jpeg,.png" prefix="invoice" @change="photoChanged" />
                                </template>
                                <template v-else>
                                    <div v-for="photo in this.getUploadsWithPrefix('invoice')" class="c-invoice__photos--photo" :key="photo.image.name + '2'">
                                        <img :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                    </div>
                                </template>
                            </div>

                            <div class="text-sm mt-2 text-red" v-if="!v.userData.articlelist.$each[index].userData.photos.isValidInvoice">
                                <div v-html="$tr('fotobijvoegenfactuurfout')">
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="c-device__inner">
            <div class="c-device__info order-lg-1">
                <div class="c-device__heading">
                    <h3 v-html="$tr('storingofprobleem')"></h3>
                    <p v-if="!isControll" v-html="$tr('gedetailleerdeomschrijvingstoring')"></p>
                </div>
                <div v-if="!userData.shouldShowDescriptionDropdowns" class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl"><span v-html="$tr('omschrijving')"></span><span v-if="editable" class="required-field">*</span></div>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <textarea
                                class="form-control"
                                v-model="article.userData.description"
                                :placeholder="descriptionPlaceholder"
                                :maxlength="maxDescription"
                                rows="6"
                            ></textarea>
                            <div
                                class="text-sm mt-2 text-red"
                                v-if="deviceDescriptionInvalid"
                                v-html="getValidationMessage($tr('omschrijving'), 'tooshort')"
                            ></div>
                            <small v-else v-html="$tr('resterendeinvoertekens')"></small><small> {{ charCounter }}</small>
                        </template>
                        <template v-else>
                            {{ article.userData.description }}
                        </template>
                    </div>
                </div>
                <div v-else class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl"><span v-html="$tr('description.options')"></span><span v-if="editable" class="required-field">*</span></div>
                    </div>
                    <div class="c-device__val">
                        <template v-if="editable">
                            <select v-for="(options,index) in userData.dropdownOptions" :key="index" v-model="userData.chosenDropdownOptions[index]" @change="handleOptionChange(index)" class="form-control-fullwidth form-control mt-2">
                                <option v-for="option in options" :value="option" :key="option.id" :hidden="option.default">{{ $tr(option.textTranslation) }}</option>
                        </select>
                        <div class="mt-2" v-if="userData.textFieldOption">
                            <span v-html="$tr(userData.textFieldOption.textTranslation)"></span>
                            <input id="textFieldOptionResult" class="form-control form-control-fullwidth" v-model="userData.textFieldOptionResult" @input="handleTextfieldChange()">
                        </div>
                        <div
                            class="text-sm mt-2 text-red"
                            v-if="deviceDescriptionInvalid"
                            v-html="getValidationMessage($tr('omschrijving'), 'isvereist')"
                        ></div>
                        </template>
                        <template v-else>
                            <span v-html="getFormattedOptionResult"></span>
                        </template>
                    </div>
                </div>
                <div v-if="shouldShowPhotoRow()" class="c-device__row">
                    <div class="c-device__key">
                        <div class="c-device__key--lbl">
                            <span v-if="isControll" v-html="$tr('foto')"></span>
                            <span v-else v-html="$tr('fotobijvoegen')"></span>
                        </div>
                    </div>
                    <div class="c-device__val">
                        <div class="c-device__photos">
                            <template v-if="editable">
                                <div v-for="(photo) in this.getUploadsWithPrefix('storing')" class="c-device__photos--photo" :key="photo.image.name">
                                    <img v-if="photo.blobUrl !== ''" :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                    <div v-else class="c-device__photos--addphoto flex-column p-2">
                                        <i class="fal fa-file"></i>
                                        <small class="pt-1">{{ photo.image.name }}</small>
                                    </div>
                                    <button class="c-device__photos--deletephoto" @click="resetPhoto(photo.index, 'storing')"><i class="fal fa-times"></i></button>
                                </div>
                                <div class="c-device__photos--addphoto" @click="$refs.uploadPhotoStoring.click()" v-if="this.getUploadsWithPrefix('storing').length <= (productGroupIsHiSenseTv() ? 3 : 0)">
                                    <i class="fal fa-photo-video"></i>
                                    <i class="fal fa-plus"></i>
                                </div>
                                <input class="d-none" ref="uploadPhotoStoring"  type="file" accept=".jpg,.jpeg,.png,.mov,.mp4" prefix="storing" @change="photoChanged" />
                            </template>
                            <template v-else>
                                <div v-for="photo in this.getUploadsWithPrefix('storing')" class="c-device__photos--photo" :key="photo.image.name + '2'">
                                    <img :src="photo.blobUrl" height="100" width="150" alt="" loading="lazy" />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RequiredFieldExplanation v-if="!isControll" />
    </div>
</template>

<script>
import moment from 'moment';
import articleHelper from '../../../../util/articleHelper';
import DatePicker from 'vue2-datepicker';
import RequiredFieldExplanation from '../../components/forms/required-field-explanation.vue';
import axios from 'axios';
import { nanoid } from 'nanoid'

export default {
    name: 'device',
    data() {
        return {
            hideImage: false,
            maxDescription: 400,
            photoBlobUrl: null,
            descriptionPlaceholder: this.$tr('omschrijvingvoorbeeld' + this.article.productDescription.toLowerCase()),
            warrantyYear: 0,
        };
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
            required: false
        },
        isControll: {
            type: Boolean,
            default: false,
            required: false
        },
        index: {
            type: Number,
            required: true
        },
        article: {
            type: Object,
            required: true
        },
        brand: {
            type: String,
            required: true
        },
        imageRight: {
            type: Boolean,
            default: false,
            required: false
        },
        userData: {
            type: Object,
            required: true
        },
        deviceDescriptionInvalid: {
            type: Boolean,
            default: false
        },
        devicePurchaseDateInvalid: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            default: null,
            required: true
        }
    },
    components: {
        DatePicker,
        RequiredFieldExplanation
    },
    computed: {
        serialnumberRequired() {
            const currentProductGroup = this.userData.articlelist[0].productGroup;

            if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                return true;
            } else {
                return false;
            }
        },
        deviceAge() {
            const purchaseDate = this.article.userData.purchaseDate;
            if (purchaseDate === '') return 0;
            const a = moment(new Date());
            const b = moment(purchaseDate);
            return a.diff(b, 'years');
        },
        charCounter() {
            return this.maxDescription - this.article.userData.description.length;
        },
        getFormattedDate() {
            if (this.article.userData.purchaseDate) {
                return moment(String(this.article.userData.purchaseDate)).format('DD-MM-YYYY');
            }
            return '';
        },
        getFormattedOptionResult(){
            let result = ""
            let parts = this.article.userData.description.split('|');
            for(let index in parts){
                let part = parts[index];
                result += part + "<br>"
            }
            return result.slice(0, -4);
         
        }
    },
    created() {
        this.setWarrantyYear();
        // only get the initialOption once
        if(!this.userData.shouldShowDescriptionDropdowns){
            this.setInitialOptions(this.article.productGroup);
        }
        this.v.userData.articlelist.$touch()
    },
    methods: {
        clearPurchaseDate() {
            if (this.article.userData.purchaseDate) {
                this.article.userData.purchaseDate = '';
            }
        },
        productGroupIsHiSenseTv() {
            const currentProductGroup = this.userData.articlelist[0].productGroup;

            if (this.brand.toLowerCase() === 'hisense' && (currentProductGroup === 'HV_TV' || currentProductGroup === 'HB_TV')) {
                return true;
            } else {
                return false;
            }
        },
        setWarrantyYear() {
            let formData = new FormData();
            formData.append('brand', this.brand);
            axios
                .post(`/api/Warranty/PostWarranty`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.warrantyYear = parseInt(response.data.warranty);
                })
                .catch((e) => {});
        },
        beforeDateExclude(date) {
            var d = new Date();
            var dNow = new Date();
            // 15 jaar
            d = d.setFullYear(d.getFullYear() - 15);
            return date < d || date > dNow;
        },
        altImageUrl(event) {
            event.target.src = 'https://www.atagonline.nl/beeldenbank?imageName=unknown';
            this.hideImage = true;
        },
        getValidationMessage(field, type) {
            if (type === 'invalid') {
                return field + ' ' + this.$tr('isnietgeldig');
            } else if (type === 'maxlength6') {
                return field + ' ' + this.$tr('maximalelengte6');
            } else if (type === 'alphanum') {
                return field + ' ' + this.$tr('ongeldigekarakters');
            } else if (type === 'tooshort') {
                if (field === this.$tr('omschrijving')) {
                    return this.$tr('tenminste9karakters');
                }
                return field + ' ' + this.$tr('tenminste9karakters');
            }
            return field + ' ' + this.$tr('isvereist');
        },
        reformatArticleNumber(value) {
            return articleHelper.toReadableArticleNumber(value);
        },
        reformatModelNumber(value) {
            return articleHelper.toReadableModelNumber(value);
        },
        photoChanged(e) {
            const prefix = e.target.attributes["prefix"].value;
            const camelCasedPrefix = prefix[0].toUpperCase() + prefix.slice(1)

            if (this.$refs[`uploadPhoto${camelCasedPrefix}`].value === null) {
                return;
            }

            const file = e.target.files[0];

            axios.get(`/api/image/GetDownSizeData`).then((response) => {
                let maxWidth = response.data.width;
                let maxHeight = response.data.height;
                let qualityPercent = parseInt(response.data.qualityPercent);
                let initialUploadSize = parseInt(response.data.initialUploadSize);

                if (file.size > initialUploadSize * 1024 * 1024) {
                    e.preventDefault();
                    alert(this.$tr('fototegroot'));
                    return;
                }

                if (
                    file.type !== 'image/jpg' &&
                    file.type !== 'image/jpeg' &&
                    file.type !== 'image/png' &&
                    file.type !== 'video/mp4' &&
                    file.type !== 'video/quicktime'
                ) {
                    e.preventDefault();
                    alert(this.$tr('fotoongeldig'));
                    return;
                }

                if (file.type === 'video/mp4' || file.type === 'video/quicktime') {
                    this.convertFileToBase64String(file).then((data) => {
                        var videoPlayer = document.createElement('video');
                        videoPlayer.setAttribute('style', 'display: none;');
                        var thumbnailCanvas = document.createElement('canvas');
                        thumbnailCanvas.setAttribute('style', 'display: none;');

                        // Set the video source and play it
                        videoPlayer.src = URL.createObjectURL(file);
                        videoPlayer.play();
                        let upperThis = this;
                        // Capture a frame from the video and display it as a thumbnail
                        videoPlayer.addEventListener('loadeddata', function () {
                            var canvasContext = thumbnailCanvas.getContext('2d');
                            canvasContext.drawImage(videoPlayer, 0, 0, thumbnailCanvas.width, thumbnailCanvas.height);

                            // Convert the canvas content to a data URL and set it as the source of the image
                            this.photoBlobUrl = thumbnailCanvas.toDataURL('image/png');
                            upperThis.updateThumbnail(this.photoBlobUrl);
                        });
                        this.updatePhoto({
                            image: file,
                            prefix: prefix,
                            index: nanoid(16),
                            base64: data,
                            blobUrl: this.photoBlobUrl
                        });
                    });
                } else {
                    this.compressImage(file, maxWidth, maxHeight, qualityPercent).then((data) => {
                        this.photoBlobUrl = URL.createObjectURL(file);
                        this.updatePhoto({
                            image: file,
                            base64: data,
                            prefix: prefix,
                            index: nanoid(16),
                            blobUrl: this.photoBlobUrl
                        });
                    });
                }
            });
        },
        resetPhoto(index, prefix) {
            const camelCasedPrefix = prefix[0].toUpperCase() + prefix.slice(1)

            this.photoBlobUrl = null;
            this.$refs[`uploadPhoto${camelCasedPrefix}`].value = null;
            this.article.userData.photos = this.article.userData.photos.filter((photo) => photo.index !== index);
            this.v.userData.articlelist.$touch()
        }, 
        updatePhoto(object) {
            if (object) this.article.userData.photos.push(object);
            this.v.userData.articlelist.$touch()
        },
        updateThumbnail(image, index) {
            if (this.article.userData.photos.length > 0) {
                this.article.userData.photos.filter((photo) => photo.index === index)[0].blobUrl = image;
            }
        },
        hasPhoto() {
            if (this.article.userData.photos.length === 0) {
                return false;
            }
            return true;
        },
        shouldShowPhotoRow() {
            if (this.isControll && this.article.userData.photos.length >= 3) {
                return false;
            }

            return true;
        },
        getNextIndexFromPrefix(prefix) {
            return this.getUploadsWithPrefix(prefix).length;
        },
        getUploadsWithPrefix(prefix) {
            return this.article.userData.photos.filter((photo) => photo.prefix.startsWith(prefix))
        },
        compressImage(file, maxWidth, maxHeight, qualityPercent) {
            return new Promise(function (resolve) {
                let url = window.URL || window.webkitURL;
                let image;
                let width;
                let height;
                image = new Image();
                var objectUrl = url.createObjectURL(file);
                image.src = objectUrl;
                image.onload = function () {
                    width = this.width;
                    height = this.height;
                    url.revokeObjectURL(objectUrl);
                    var canvas = document.createElement('canvas');

                    // calculate the width and height, constraining the proportions
                    if (width > height) {
                        if (width > maxWidth) {
                            height = Math.round((height *= maxWidth / width));
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width = Math.round((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }
                    // resize the canvas and draw the image data into it
                    canvas.width = width;
                    canvas.height = height;
                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);
                    resolve(canvas.toDataURL(file.type, qualityPercent / 100)); // get the data from canvas as 70% JPG (can be also PNG, etc.)
                };
            }).catch((e) => {});
        },
        setInitialOptions(productGroup) {
            let formData = new FormData();
            formData.append('productGroup', productGroup);
            axios
                .post(`/api/Option/GetInitialOptions`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    if(response.data && response.data.length > 0){
                        this.userData.shouldShowDescriptionDropdowns = true;
                        this.userData.dropdownOptions[0] = response.data;
                       
                        // add default option
                        let defaultOption = {
                            id:'-1_0',
                            textTranslation: 'description.options.maakeenkeuze',
                            default: true
                        }                    
                        this.userData.dropdownOptions[0].push(defaultOption);
                        this.userData.chosenDropdownOptions[0] = defaultOption;
                    }
                })
                .catch((e) => {});
        },
        getOptionByPreviousId(optionId,selectorIndex){
            let formData = new FormData();
            formData.append('id', optionId);
            axios
                .post(`/api/Option/GetOptionsByPreviousID`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    if (response.data) {
                        // check if the option is a textfield 
                        // (this assumes a text field can only be last in the list and as the only option)
                        if(response.data.length === 1 && response.data[0].questionTypeId === 2){
                            this.userData.textFieldOption = response.data[0];
                        }else{
                            let newIndex = selectorIndex+1;
                            // add new options to array
                            this.userData.dropdownOptions.splice(newIndex, 0, response.data);
                            // add default option
                            let defaultOption = {
                                id:'-1_'+newIndex,
                                textTranslation: 'description.options.maakeenkeuze',
                                default: true
                            }                    
                            this.userData.dropdownOptions[newIndex].push(defaultOption);
                            this.userData.chosenDropdownOptions[newIndex] = defaultOption;
                        }
                        
                    }
                })
                .catch((e) => {});
        },     
        handleOptionChange(selectorIndex){
            let chosenOption = this.userData.chosenDropdownOptions[selectorIndex];     
   
            // if a user changes a dropdown, we need to reset all oncoming fields
            this.userData.dropdownOptions.splice(selectorIndex+1);
            this.userData.chosenDropdownOptions.splice(selectorIndex+1);
            this.userData.textFieldOption = null;
            this.userData.textFieldOptionResult = "";         
            
            if(chosenOption.isLastOption){
                this.processDropdownAnswers();              
                
            }else{
                this.article.userData.description = "";
                this.getOptionByPreviousId(chosenOption.id, selectorIndex);
            }
            
        },
        handleTextfieldChange(){
            if(this.userData.textFieldOptionResult !== ""){
                this.processDropdownAnswers();
            }else{
                this.article.userData.description = "";
            }
        },
        processDropdownAnswers(){
            let result = "";
            for(let index in this.userData.chosenDropdownOptions){
                let chosenOption = this.userData.chosenDropdownOptions[index];
                result += this.$tr(chosenOption.textTranslation)
                result += '|';
            }
            if(this.userData.textFieldOption){
                result += this.userData.textFieldOptionResult;
                result += '|';
            }
            result = result.slice(0, -1);
            this.article.userData.description = result;
        }
    }
};
</script>
