function toReadableModelNumber(modelNumber) {
    let res = '';
    if (modelNumber != null) {
        for (let i = 0; i < modelNumber.length; i++) {
            if (modelNumber[i] !== '/') {
                res += modelNumber[i];
            } else {
                break;
            }
        }
    }
    return res;
}

function toReadableArticleNumber(articleNumber) {
    let res = '';
    if (articleNumber != null) {
        for (let i = 0; i < articleNumber.length; i++) {
            if (articleNumber[i] === '0' && res.length === 0) {
                continue;
            } else {
                res += articleNumber[i];
            }
        }
    }
    return res;
}

export default { toReadableModelNumber, toReadableArticleNumber };
