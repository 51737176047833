<template>
    <div class="c-requiredfieldexplanation">
        <p v-html="$tr('veldenverplicht')"></p>
    </div>
</template>

<script>
export default {
    name: 'required-field-explanation',
    data() {
        return {};
    }
};
</script>
