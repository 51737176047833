<template>
    <div class="c c-confirm">
        <div class="c c-stap">
            <h3 class="c-stap__header" v-html="$tr('controleergegevens')"></h3>
            <div class="c-stap__body mt-1">
                <p v-html="$tr('foutjesnelgemaakt')"></p>
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__header">
                <h3 v-html="$tr('persoonlijkegegevens')"></h3>
                <button class="btn btn-link btn-hasicon" @click="$emit('editPersonalData')">
                    <i class="fal fa-edit"></i>
                    <span v-html="$tr('aanpassen')"></span>
                </button>
            </div>
            <div class="c-stap__body">
                <FormField :label="$tr('aanhef')" :value="getSexe" />
                <FormField :label="$tr('achternaam')" :value="userData.lastName" />
                <FormField :label="$tr('adres')" :value="getAddress" />
                <FormField :label="$tr('telefoonnummer')" :value="userData.phoneNumber" />
                <FormField :label="$tr('emailadres')" :value="userData.emailAddress" />
                <template v-if="userData.retailer">
                    <FormField :label="$tr('klantsoort')" :value="getClientType" />
                    <template v-if="userData.client.clientType === 'client'">
                        <FormField :label="$tr('plaats')" :value="userData.client.clientCity" />
                        <FormField :label="$tr('bedrijfsnaam')" :value="userData.client.clientCompanyName" />
                        <FormField :label="$tr('contactpersoon')" :value="userData.client.clientContactPerson" />
                        <FormField :label="$tr('klantnummer')" :value="userData.client.clientNumber" />
                    </template>
                </template>
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__header">
                <h3 v-if="userData.articlelist.length === 1" v-html="$tr('uwapparaat')"></h3>
                <h3 v-else v-html="$tr('uwapparaten')"></h3>
                <button class="btn btn-link btn-hasicon" @click="$emit('editDevices')">
                    <i class="fal fa-edit"></i>
                    <span v-html="$tr('aanpassen')"></span>
                </button>
            </div>
            <div class="c-stap__body">
                <div class="c-device__overview">
                    <Device
                        v-for="(article, index) in userData.articlelist"
                        :index="index"
                        :brand="brand"
                        :article="article"
                        :userData="userData"
                        :key="article.productId"
                        :editable="false"
                        :isControll="true"
                        :v="v"
                    />
                </div>
            </div>
        </div>

        <div class="c c-stap">
            <div class="c-stap__body mt-1">
                <div class="form-check">
                    <input class="custom-control-input" type="checkbox" id="terms" v-model="userData.terms" />
                    <label class="custom-control-label" for="terms">
                        <span v-html="$tr('ikhebde')"></span>
                        <a :href="getLinkDisclaimer" target="_blank" title="termsandconditions" v-html="$tr('servicevoorwaarden')"></a>
                        <span v-html="$tr('gelezen')"></span>
                    </label>
                    <span class="error field-validation-error" v-if="wizard.validationTerms && !userData.terms" v-html="$tr('accepteervoorwaarden')"></span>
                </div>
                <div class="form-check">
                    <input class="custom-control-input" type="checkbox" id="termsInstaller" v-model="userData.termsInstaller" />
                    <label class="custom-control-label" for="termsInstaller">
                        <span v-html="$tr('ikbenbewustvanmonteurkosten')"></span>
                    </label>
                    <span class="error field-validation-error" v-if="wizard.validationTerms && !userData.termsInstaller" v-html="$tr('accepteervoorwaarden')"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import moment from 'moment';
import FormField from '../forms/formfield.vue';
import Device from '../devices/device.vue';

export default {
    name: 'step-confirm',
    data() {
        return {};
    },
    components: {
        Device,
        FormField
    },
    computed: {
        getAddress() {
            let street = this.userData.address.streetName || '';
            let housenumber = this.userData.address.houseNumber || '';
            let housenumberadd = this.userData.address.houseNumberSuffix || '';
            let zipcode = this.userData.address.zipCode || '';
            let city = this.userData.address.city || '';

            if (street === '' || housenumber === '' || zipcode === '' || city === '') {
                return '';
            }

            let address = street + ' ' + housenumber + housenumberadd + '<br />' + zipcode + '<br />' + city;
            return address;
        },
        getSexe() {
            if (this.userData.salutation === 'V') {
                return 'Vrouw';
            } else if (this.userData.salutation === 'M') {
                return 'Man';
            } else if (this.userData.salutation === 'X') {
                return 'Anders';
            }
            return 'Onbekend';
        },
        getClientType() {
            if (this.userData.client.clientType === 'me') {
                return 'Voor mij';
            } else {
                return 'Voor de klant';
            }
        },
        getLinkDisclaimer() {
            return 'todo';
        }
    },
    methods: {},
    props: {
        brand: {
            type: String,
            default: null,
            required: true
        },
        userData: {
            type: Object,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        },
        wizard: {
            type: Object,
            default: null,
            required: true
        },
        lang: {
            type: String,
            required: true
        }
    }
};
</script>
