<template>
    <div class="c c-stap" v-if="showAddDevice">
        <h3 class="c-stap__header">{{ modelNumber }}</h3>
        <div class="c-stap__body">
            <div class="c-adddevice">
                <div class="c-adddevice__row">
                    <label for="inputModelNumber" class="c-adddevice__label">
                        <span>
                            <span v-html="$tr('modelnummer')"></span><span>*</span><br />
                        </span>
                        <span class="customtooltip">
                            <span class="tooltip-item"></span>
                            <span class="tooltip-content clearfix"> 
                                <img :src="`/img/modelnummer-tooltip.png`" />
                                <small v-html="$tr('modelnummertooltip')"></small> 
                            </span>
                        </span>
                    </label>
                    <div class="model-width">                    
                        <div class="c-adddevice__field">
                            <autocomplete
                                id="products"
                                anchor="modelNumber"
                                param="modelNumber"
                                :placeholder="$tr('bijvoorbeeldcx4612d')"
                                url="/api/product/GetProductsUniqueModelNumbers"
                                :classes="{ input: 'form-control' }"
                                label=""
                                :min="2"
                                :customParams="{language: this.userData.country.toUpperCase(), brand: this.brand }"
                                :on-select="setModelNumber"
                            ></autocomplete>
                            <div class="c-adddevice__loader" :class="{ loading: isLoading }">
                                <span class="btn-loader"><i class="fal fa-spinner-third"></i></span>
                            </div>
                        </div>
                        <small class="model-footer" v-html="$tr('modelnummervoettekst')"></small>
                    </div>
                </div>
                <div class="c-adddevice__notfound" v-if="productNotFound" v-html="$tr('productnietgevonden')"></div>
            </div>
            <RequiredFieldExplanation />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import RequiredFieldExplanation from '../../components/forms/required-field-explanation.vue';

export default {
    name: 'step-add-devices',
    data() {
        return {
            products: null,
            modelNumber: '',
            selectedBatch: null,
            productNotFound: false,
            isLoading: false,
            categories: [],
            selectedCategory: ''
        };
    },
    mounted() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        let product = params.get('product');
        if (product) {
            this.getArticle(product);
        }
    },
    methods: {
        setModelNumber(value) {
            this.modelNumber = value.modelNumber;
            this.getArticle();
        },
        getArticle() {
            this.isLoading = true;
            axios
                .get(`/api/product/GetProductsByModelNumber?modelNumber=${this.modelNumber}&brand=${this.brand}`)
                .then((response) => {
                    if (response.data) {
                        this.products = response.data;
                        if (this.products.length === 0) {
                            this.productNotFound = true;
                            this.isLoading = false;
                        } else {
                            let article = {
                                modelNumber: this.products[0].modelNumber,
                                articleNumber: this.products[0].articleNumber,
                                productGroup: this.products[0].productGroup,
                                productDescription: this.products[0].productDescription,
                                userData: {
                                    description: '',
                                    purchaseDate: '',
                                    photos: [],
                                    serialNumber: ''
                                },
                                versionNumber: this.products[0].versionNumber,
                                versionNumbers: this.products.map(function (obj) {
                                    return obj.versionNumber;
                                })
                            };

                            article.versionNumbers = new Set(article.versionNumbers);
                            this.userData.articlelist.push(article);
                            this.modelNumber = null;
                            this.productNotFound = false;
                            this.$emit('hideAddDevice');
                            this.isLoading = false;
                            this.selectedCategory = '';
                        }
                    }
                })
                .catch((e) => {
                });
        },
        deleteArticle(index) {
            this.userData.articlelist.splice(index, 1);
        }
    },
    components: {
        RequiredFieldExplanation
    },
    computed: {},
    props: {
        brand: {
            type: String,
            default: null,
            required: true
        },
        showAddDevice: {
            type: Boolean,
            default: true,
            required: false
        },
        userData: {
            type: Object,
            default: null,
            required: true
        },
        v: {
            type: Object,
            default: null,
            required: true
        }
    }
};
</script>
