<template>
    <div class="c-formfield" v-if="value">
        <div class="c-formfield__lbl">{{ label }}</div>
        <div class="c-formfield__val" v-html="value"></div>
    </div>
</template>

<script>
export default {
    name: 'formfield',
    data() {
        return {};
    },
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            default: '',
            required: false
        }
    }
};
</script>
